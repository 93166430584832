import React from "react"
import PropTypes from "prop-types"
import ImageCardsLayout from "../components/ImageCardsLayout/ImageCardsLayout"
import Layout from "../components/Layout/Layout"
import { formatPrdName } from "../helpers/BuildImageCardObject"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head";

const Product = props => {
  const { pageContext } = props
  const {  apiData, componentProps, seoData, globalComponents,  dataLayer } =
    pageContext || {}
  const {imageData, prdNames, slugs, prdIds} = apiData || {}
 
  const imageCards =
    (componentProps && componentProps[0]?.ImageCardsLayout) || {}

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: "Product Page",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }

  //returning the value based on the product Id
  function getValue(list, prdId) {
    const obj = list?.find(item => item?.id === prdId)
    return obj ? obj?.value : null
  }

  function buildImageCardData(data) {
    data?.cardsCollection?.items?.map((item, index) => {
      item.title = formatPrdName(prdNames[index])
      ;(item.image = {
        url: getValue(imageData, prdIds[index]),
        title: item.image.title,
      }),
        (item.link.url = getValue(slugs, prdIds[index]))
    })
    return data
  }

  const imageCardsDetails = buildImageCardData(imageCards)

  return (
    <Layout seoData={seoData} globalComponents={globalComponents}>
      <ImageCardsLayout {...imageCardsDetails} />
    </Layout>
  )
}

export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
    </>
  )
}

Product.propTypes = {
  pageContext: PropTypes.shape({
    imageData: PropTypes.array,
    prdNames: PropTypes.array,
    contentfulData: PropTypes.array,
    slugs: PropTypes.array,
    prdIds: PropTypes.array,
    dataLayer: PropTypes.object,
  }),
}

export default Product
